import { ConfigProvider as AntProvider, App } from 'antd'
import locale from 'antd/es/locale/en_US'
import classNames from 'classnames'

const ConfigProvider = ({ children }) => {
  return (
    <AntProvider
      locale={locale}
      prefixCls={'pv'}
      theme={{
        hashed: true,
        token: {
          colorPrimary: '#57806e',
          borderRadius: 0,
        },
        components: {
          Select: {
            optionHeight: 36,
            multipleItemHeightLG: 36,
            singleItemHeightLG: 36,
          },
          Button: {
            controlHeightLG: 36,
          },
          Layout: {
            headerBg: '#202123',
            siderBg: '#202123',
          },
          Menu: {
            darkItemBg: '#202123',
            darkItemColor: '#ffffff',
            darkItemHoverColor: '#ffffff',
            darkItemHoverBg: '#57806e',
            darkItemSelectedBg: '#57806e',
            darkItemSelectedColor: '#ffffff',
            darkSubMenuItemBg: '#202123',
          },
        },
      }}
    >
      <App>{children}</App>
    </AntProvider>
  )
}

export const withPrefix: (...names: string[]) => string = (...names) => {
  const key = 'pv'
  return classNames(
    names.filter((value) => !!value.trim()).map((name) => (!name.startsWith(key) ? `${key}-${name}` : name)),
  ).trim()
}

export default ConfigProvider
