import { FC } from 'react'

const LogoIcon: FC<{ width?: number | string; height?: number | string }> = ({ width = '1em', height = '1em' }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' viewBox='0 0 28 28'>
      <path
        fill='#FEFEFE'
        d='M13.2594 0C9.74404 0.00412431 6.37386 1.40241 3.88814 3.88814C1.40241 6.37386 0.00412431 9.74404 0 13.2594L0 27.4202H14.1608C17.6762 27.4161 21.0464 26.0178 23.5321 23.5321C26.0178 21.0464 27.4161 17.6762 27.4202 14.1608V0H13.2594ZM12.5162 16.3582H25.0731C24.8334 17.5511 24.398 18.6962 23.7844 19.747H9.12729L12.5162 16.3582ZM14.6463 14.228L18.0028 10.8715H25.2936V14.1668C25.2936 14.1896 25.2936 14.2124 25.2936 14.234L14.6463 14.228ZM25.2936 8.73649H20.139L25.2936 3.58187V8.73649ZM13.2594 2.13018H23.7353L2.13018 23.7353V13.2594C2.13525 10.3093 3.30942 7.48148 5.39545 5.39545C7.48148 3.30942 10.3093 2.13525 13.2594 2.13018ZM14.1608 25.2936H3.58426L6.99831 21.8772H22.1769C21.1403 22.9579 19.8957 23.8177 18.5181 24.4049C17.1405 24.992 15.6583 25.2943 14.1608 25.2936Z'
      ></path>
    </svg>
  )
}

export default LogoIcon
