import { MenuOutlined, SettingOutlined } from '@ant-design/icons'
import { useAccount } from '@src/sdk/providers/AccountProvider'
import { useAuth } from '@src/sdk/providers/AuthProvider'
import { useCompanySelect } from '@src/sdk/providers/CompanySelectProvider'
import { withPrefix } from '@src/sdk/providers/ConfigProvider'
import { useLayoutState } from '@src/sdk/providers/LayoutProvider'
import { Button, Flex, Layout, Menu, MenuProps, Typography } from 'antd'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import './Navigation.scss'

const { Header } = Layout

const TopNav: FC = () => {
  const { setPageLoading } = useLayoutState()
  const { selectCompany: companySelectModal } = useCompanySelect()
  const { logout } = useAuth()
  const { company } = useAccount()
  const { toggleSider } = useLayoutState()
  const navigate = useNavigate()

  const navItems: MenuProps['items'] = [
    {
      label: 'Switch Company',
      key: '/companies',
      onClick: companySelectModal,
      icon: <SettingOutlined />,
    },
    {
      label: 'Logout',
      key: '/logout',
      onClick: async () => {
        setPageLoading(true)
        await logout()
          .then(() => {
            navigate('/login')
          })
          .finally(() => setPageLoading(false))
      },
      icon: <SettingOutlined />,
    },
  ]
  return (
    <Header className={withPrefix('top-nav')}>
      <Flex justify={'space-between'} align={'center'} style={{ width: '100%', height: '100%' }}>
        <Flex justify={'space-between'} align={'center'} gap={16}>
          <Button className={withPrefix('side-nav-btn')} icon={<MenuOutlined />} onClick={toggleSider} />
          {company && (
            <Typography.Title level={4} className={withPrefix('top-nav-company-name')}>
              {company.name}
            </Typography.Title>
          )}
        </Flex>

        <Menu selectedKeys={[]} theme={'dark'} items={navItems} mode={'horizontal'} style={{ flex: 1 }} />
      </Flex>
    </Header>
  )
}

export default TopNav
