import { initialState } from '@data-client/core'
import { Controller, getDefaultManagers } from '@data-client/react'
import { ExternalDataProvider, prepareStore } from '@data-client/react/redux'
import { FullPageLoader } from '@src/sdk/components/loader/Loader'
import { AuthProvider } from '@src/sdk/providers/AuthProvider'
import ConfigProvider from '@src/sdk/providers/ConfigProvider'
import { LayoutProvider } from '@src/sdk/providers/LayoutProvider'
import { NotificationProvider } from '@src/sdk/providers/NotificationProvider'
import { Suspense } from 'react'
import { CookiesProvider } from 'react-cookie'
import { RouteProvider } from './RouteProvider'

const managers = getDefaultManagers()

const { store, selector, controller } = prepareStore(initialState, managers, Controller)

const Root = () => {
  return (
    <ExternalDataProvider store={store} selector={selector} controller={controller} devButton={'bottom-right'}>
      <ConfigProvider>
        <CookiesProvider>
          <AuthProvider>
            <Suspense fallback={<FullPageLoader loading={true} />}>
              <NotificationProvider>
                <LayoutProvider>
                  <RouteProvider />
                </LayoutProvider>
              </NotificationProvider>
            </Suspense>
          </AuthProvider>
        </CookiesProvider>
      </ConfigProvider>
    </ExternalDataProvider>
  )
}
export default Root
