import { AsyncBoundary } from '@data-client/react'
import { FullPageLoader } from '@src/sdk/components/loader/Loader'
import { ErrorBoundary } from '@src/sdk/providers/ApiErrorBoundary'
import { useAuth } from '@src/sdk/providers/AuthProvider'
import { withPrefix } from '@src/sdk/providers/ConfigProvider'
import { Flex, Layout, Spin } from 'antd'
import { lazy } from 'react'
import { Outlet, RouteObject, RouterProvider } from 'react-router'
import { createBrowserRouter } from 'react-router-dom'
import Login from 'src/components/auth/Login'
import NotFound from 'src/components/results/NotFound'
import Authorized from 'src/layouts/Authorized'
import Unauthorized from 'src/layouts/Unauthorized'
import { MappedRoutes, RouteMap, RouteMapProps } from './RouteMap'

const WrappedComponent =
  ({ superAdmin, Component, path }: RouteMapProps) =>
  () => {
    const { authenticated, companyId } = useAuth()
    if (superAdmin && companyId !== 72 && companyId !== 1) return <NotFound />
    return !authenticated ? <Login /> : Component ? <Component /> : <NotFound />
  }

const RootRoute = () => {
  return (
    <ErrorBoundary>
      <AsyncBoundary
        fallback={
          <Flex align={'center'} justify={'center'} style={{ width: '100%', height: '100%', minHeight: '100vh' }}>
            <Spin size={'large'} />
          </Flex>
        }
      >
        <Layout className={withPrefix('site-wrapper')}>
          <Outlet />
        </Layout>
      </AsyncBoundary>
    </ErrorBoundary>
  )
}

const CompileRoutes = (routes: RouteMapProps[]) => {
  return routes
    .filter((route) => route.path)
    .reduce((acc, route) => {
      acc = acc ?? []
      acc.push({
        id: route.path ?? route.name,
        path: route.path,
        Component: WrappedComponent(route),
        children: route.nested
          ? [
              ...route.nested.map((nested) => ({
                id: `${route.path}/${nested}`,
                path: `${route.path}/${nested}`,
                Component: WrappedComponent(route),
              })),
            ]
          : undefined,
      })
      return acc
    }, [] as RouteObject[])
}

const generateRouter = () =>
  createBrowserRouter([
    {
      id: 'root',
      Component: RootRoute,
      hasErrorBoundary: true,
      children: [
        {
          id: 'logout',
          path: RouteMap.logout.path,
          hasErrorBoundary: true,
          Component: lazy(() => import('src/components/auth/Logout')),
        },
        {
          id: 'authorized',
          Component: Authorized,
          hasErrorBoundary: true,
          children: CompileRoutes(MappedRoutes.filter((it) => it.protected)),
        },
        {
          id: 'unauthorized',
          Component: Unauthorized,
          hasErrorBoundary: true,
          children: CompileRoutes(MappedRoutes.filter((it) => !it.protected)),
        },
      ],
    },
  ])

const RouteProvider = () => (
  <RouterProvider router={generateRouter()} fallbackElement={<FullPageLoader loading={true} />} />
)
export { RouteProvider }
