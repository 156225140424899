import Icon, {
  ApiOutlined,
  AuditOutlined,
  BugOutlined,
  GlobalOutlined,
  LockOutlined,
  MobileOutlined,
  SettingOutlined,
  WifiOutlined,
} from '@ant-design/icons'
import { LogoIcon } from '@src/sdk/components/logo'
import { ComponentType, lazy, ReactNode } from 'react'

export type RouteMaps = { [key in string]: RouteMapProps }

export type RouteMapProps = {
  path: string
  name: string
  nested?: string[]
  protected?: boolean
  navItem?: boolean
  children?: RouteMaps
  superAdmin?: boolean
  icon?: ReactNode
  Component?: ComponentType
}

const RouteMap: RouteMaps = {
  login: {
    path: '/login',
    name: 'Login',
    protected: false,
    Component: lazy(() => import('src/components/auth/Login')),
  },
  logout: {
    path: '/logout',
    name: 'Logout',
    protected: true,
  },
  companies: {
    path: '/companies',
    name: 'Companies',
    protected: true,
  },
  index: {
    path: '/',
    name: 'Home',
    navItem: true,
    protected: true,
    icon: (
      <Icon>
        <LogoIcon />
      </Icon>
    ),
    Component: lazy(() => import('src/components/home/Home')),
  },
  home: {
    path: '/home',
    name: 'Home',
    protected: true,
    Component: lazy(() => import('src/components/home/Home')),
  },
  domains: {
    path: '/domains',
    name: 'Domains',
    navItem: true,
    protected: true,
    nested: [':id'],
    icon: <GlobalOutlined />,
    Component: lazy(() => import('src/components/domains/Index')),
  },
  integrations: {
    path: '/integrations',
    name: 'Integrations',
    navItem: true,
    protected: true,
    nested: [':id'],
    icon: <ApiOutlined />,
    Component: lazy(() => import('src/components/integrations/Index')),
  },
  webhooks: {
    path: '/webhooks',
    name: 'Webhooks',
    protected: true,
    Component: lazy(() => import('src/components/webhooks/Webhooks')),
  },
  companySettings: {
    name: 'Settings',
    path: '/settings',
    navItem: true,
    protected: true,
    icon: <SettingOutlined />,
    children: {
      passbookSettings: {
        path: '/passbook',
        name: 'Passbook',
        protected: true,
        icon: <WifiOutlined />,
        Component: lazy(() => import('src/components/company/settings/passbook/Index')),
      },
    },
  },
  apiTokens: {
    path: '/tokens',
    name: 'Api Tokens',
    navItem: true,
    protected: true,
    nested: [':id'],
    icon: <LockOutlined />,
    Component: lazy(() => import('src/components/tokens/Index')),
  },
  app: {
    path: '/apps',
    name: 'App',
    navItem: true,
    protected: true,
    icon: <MobileOutlined />,
    Component: lazy(() => import('src/components/apps/Apps')),
  },
  appDeployments: {
    path: '/apps/deployments',
    name: 'App Deployments',
    nested: [':id'],
    protected: true,
    Component: lazy(() => import('src/components/apps/AppDeployments')),
  },
  users: {
    path: '/users',
    name: 'Users',
    superAdmin: true,
    protected: true,
    nested: [':id'],
    Component: lazy(() => import('src/components/users/Index')),
  },
  queue: {
    path: '/queue',
    name: 'Job Queue',
    navItem: true,
    superAdmin: true,
    protected: true,
    nested: [':type'],
    Component: lazy(() => import('src/components/queue/Index')),
  },
  logs: {
    path: '/logs',
    name: 'Logs',
    navItem: true,
    protected: true,
    nested: ['event-logs'],
    icon: <AuditOutlined />,
    Component: lazy(() => import('src/components/logs/Index')),
  },
  errorLogs: {
    path: '/logs/error-logs',
    name: 'Error Logs',
    navItem: true,
    superAdmin: true,
    protected: true,
    icon: <BugOutlined />,
    Component: lazy(() => import('src/components/logs/errors/ErrorLogs')),
  },
} as const

const FlattenMap = (map: RouteMaps) => {
  return Object.keys(map).reduce((acc, key) => {
    acc = acc ?? []
    const route = map[key]
    if (route.children) {
      const children = route.children
      Object.keys(children).forEach((child) => {
        acc.push(children[child])
      })
    }
    acc.push(route)
    return acc
  }, [] as RouteMapProps[])
}

const MappedRoutes = FlattenMap(RouteMap).filter((it) => it.path && it.Component)

const NavItems = (pvAdmin: boolean) => {
  return FlattenMap(RouteMap)
    .filter((it) => it.navItem)
    .filter((it) => (!pvAdmin ? !it.superAdmin : it))
    .map((it) => {
      const children = it.children
      if (children) {
        return {
          label: it.name,
          key: it.name,
          icon: it.icon,
          children: FlattenMap(children).map((child) => ({
            label: child.name,
            key: child.path,
            icon: child.icon,
          })),
        }
      }
      return {
        label: it.name,
        icon: it.icon,
        key: it.path ?? it.name,
      }
    })
}

export { MappedRoutes, NavItems, RouteMap }
