import { Spin } from 'antd'
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useMediaQuery } from 'react-responsive'
import { BreakpointMin } from '../components/screen/Breakpoints'

type SiderState = '' | 'hidden' | 'collapsed' | 'expanded'
const topNavHeight = 70 as const
const siderWidth = 300 as const
const siderCollapsedWidth = 70 as const

interface INavState {
  sider: {
    width: number
    state: SiderState
    collapsed: boolean
  }
  top: {
    height: number
  }
}

type LayoutStateProps = {
  pageLoading: boolean
  setPageLoading: Dispatch<SetStateAction<boolean>>
  navState: INavState
  toggleSider: () => void
  footerVisible: Boolean
  setFooterVisible: Dispatch<SetStateAction<boolean>>
}

const LayoutState = createContext<LayoutStateProps>({
  pageLoading: false,
  setPageLoading: (value) => value,
  toggleSider: () => {},
  navState: {} as INavState,
  footerVisible: true,
  setFooterVisible: (value) => value,
})

const LayoutProvider: FC<PropsWithChildren> = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: BreakpointMin.MD })
  const [pageLoading, setPageLoading] = useState(false)
  const [siderCollapsed, setSiderCollapsed] = useState(false)

  const [footerVisible, setFooterVisible] = useState(true)

  const toggleSider = () => {
    setSiderCollapsed(!siderCollapsed)
  }

  useEffect(() => {
    setSiderCollapsed(isMobile)
  }, [isMobile])

  const navState = useMemo(
    () =>
      ({
        sider: {
          width: isMobile ? 0 : siderCollapsed ? siderCollapsedWidth : siderWidth,
          state: siderCollapsed ? (isMobile ? 'hidden' : 'collapsed') : 'expanded',
          collapsed: siderCollapsed,
        },
        top: {
          height: topNavHeight,
        },
      } as INavState),
    [siderCollapsed, isMobile],
  )

  return (
    <LayoutState.Provider
      value={{
        pageLoading,
        toggleSider,
        setPageLoading,
        navState,
        footerVisible,
        setFooterVisible,
      }}
    >
      <Spin spinning={pageLoading}>{children}</Spin>
    </LayoutState.Provider>
  )
}

function useLayoutState(): LayoutStateProps {
  return useContext(LayoutState)
}

export { LayoutProvider, useLayoutState }
