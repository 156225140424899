import TopNav from '@src/components/navigation/TopNav'
import { SectionLoader } from '@src/sdk/components/loader/Loader'
import { AccountProvider } from '@src/sdk/providers/AccountProvider'
import { ErrorBoundary } from '@src/sdk/providers/ApiErrorBoundary'
import { useAuth } from '@src/sdk/providers/AuthProvider'
import { CompanySelectProvider } from '@src/sdk/providers/CompanySelectProvider'
import { useLayoutState } from '@src/sdk/providers/LayoutProvider'
import { Layout } from 'antd'
import { Suspense } from 'react'
import { Navigate, Outlet } from 'react-router'
import SideNav from 'src/components/navigation/SideNav'
import './Authorized.scss'

const { Content, Header } = Layout
const Authorized = () => {
  const { navState } = useLayoutState()
  const { authType, authenticated } = useAuth()
  if (!authenticated) return <Navigate to={'/login'} />

  return (
    <CompanySelectProvider>
      <AccountProvider>
        <Layout hasSider={navState.sider.state !== 'hidden'}>
          {authType == 'user_company' && <SideNav />}
          <Layout style={{ marginInlineStart: navState.sider.width, transition: 'all 0.2s' }}>
            {authType == 'user_company' && <TopNav />}
            <Content
              style={{
                marginTop: navState.top.height,
              }}
            >
              <Suspense fallback={<SectionLoader />}>
                <ErrorBoundary>
                  <Outlet />
                </ErrorBoundary>
              </Suspense>
            </Content>
          </Layout>
        </Layout>
      </AccountProvider>
    </CompanySelectProvider>
  )
}
export default Authorized
